import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { IoIosSearch } from "react-icons/io";

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

const SearchInput = ({ changeCallback, placeholder, filterVal }) => {
  const [timer, setTimer] = useState();
  const [value, setValue] = useState("");
  const [submit, setSubmit] = useState(false);

  const handleKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY) {
      changeCallback(value);
    }
  };

  const handleChange = (event) => {
    clearTimeout(timer);
    setValue(event.target.value);
    setTimer(setTimeout(() => setSubmit(true), WAIT_INTERVAL));
  };

  useEffect(() => {
    if (submit) {
      changeCallback(value);
      setSubmit(false);
    }
  }, [submit]);

  useEffect(() => {
    if (!value) changeCallback("");
  }, [value]);

  useEffect(() => {
    if (filterVal) {
      clearTimeout(timer);
      setValue(filterVal);
      setTimer(setTimeout(() => setSubmit(true), WAIT_INTERVAL));
    }
  }, [filterVal]);

  return (
    <Container>
      <Search
        type="text"
        value={value}
        placeholder={placeholder || "Search"}
        onChange={(event) => handleChange(event)}
        onKeyDown={(event) => handleKeyDown(event)}
      />
      <SearchButton>
        <IoIosSearch />
      </SearchButton>
    </Container>
  );
};

export default SearchInput;

const Container = styled.div`
  display: flex;
`;

const Search = styled.input`
  width: 100%;
  padding: 1rem;
  font-size: 2.3rem;
  font-weight: bold;
  outline: none;
  border: 0;
  border-bottom: 0.2px solid #bebebe;

  &::placeholder {
    color: #bebebe;
  }

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    font-size: 1.8rem;
    font-weight: normal;
  }
`;

const SearchButton = styled.button`
  outline: none;
  border: none;
  background-color: white;
  float: right;
  border-bottom: 0.2px solid #bebebe;

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;
