import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Select from "react-select";
import axios from "axios";
import EstablishmentList from "../components/EstablishmentList";
import Layout from "../components/Layout";
import {
  Body,
  Container,
  Title,
  Subtext,
  LoaderWrapper,
} from "../common/styled-components";
import LoadingSpinner from "../components/LoadingSpinner";
import SearchInput from "../components/SearchInput";
import { LinkHeader } from "../components/LinkHeader";
import { lgSearch } from "../common/firebaseAnalytics";
import CafeImg from "../img/cafe-filter.jpg";
import BurgerImg from "../img/burger-filter.jpg";
import KoreanImg from "../img/korean-filter.jpg";
import JapaneseImg from "../img/japanese-filter.jpg";
import BakeryImg from "../img/bakery-filter.jpg";
import CocktailImg from "../img/cocktails-filter.jpg";
import RamenImg from "../img/ramen-filter.jpg";
import VeganImg from "../img/vegan-filter.jpg";
import AusImg from "../img/australian-filter.jpg";
import ItalianImg from "../img/italian-filter.jpg";
import FrenchImg from "../img/french-filter.jpg";

export default ({ data }) => {
  const [currentCity, setCurrentCity] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const [resultsList, setResultsList] = useState([]);
  const [searchTotal, setSearchTotal] = useState(0);
  const [currSearchPageCount, setCurrSearchPageCount] = useState(1);

  const [runningList, setRunningList] = useState([]);
  const [runningTotal, setRunningTotal] = useState(0);
  const [currPageCount, setCurrPageCount] = useState(1);
  const [filter, setFilter] = useState("");

  const cityOptions = [{ value: "sydney", label: "Sydney" }];

  const customStyles = {
    control: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      border: "none",
      borderRadius: "5px",
      boxShadow: "0 2px 5px 0 rgba(190, 190, 190, 0.5)",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const fetchPageOfEst = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `/.netlify/functions/establishmentEntries?page=${currPageCount}`
      );
      setRunningList(runningList.concat(result.data.results));
      setRunningTotal(result.data.total);
      setCurrPageCount(currPageCount + 1);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPageOfSearch = async ({ isNewSearch }) => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `/.netlify/functions/establishmentSearch?page=${
          isNewSearch ? 1 : currSearchPageCount
        }&query=${searchQuery}`
      );
      setResultsList(
        isNewSearch
          ? result.data.results
          : resultsList.concat(result.data.results)
      );
      setSearchTotal(result.data.total);
      setCurrSearchPageCount(isNewSearch ? 2 : currSearchPageCount + 1);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilter = (filter) => {
    return () => {
      setFilter(filter);
    };
  };

  useEffect(() => {
    if (searchQuery) {
      fetchPageOfSearch({ isNewSearch: true });
      lgSearch("directory", { query: searchQuery });
    } else {
      setResultsList([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    setCurrentCity(cityOptions[0]);
    fetchPageOfEst();
  }, []);

  return (
    <Layout>
      <LinkHeader
        text={
          "Don't see your favourite place?\nClick here and help us add them! ❤"
        }
        url={"https://forms.gle/jkiqSfEUaXXutcFm9"}
      />
      <Container>
        <Opening>
          <Heading>
            <City>{currentCity.value}!</City> Let’s do our bit to keep our local
            and favourite eateries afloat during this sh*t show (these uncertain
            times).
          </Heading>
        </Opening>
      </Container>
      <Body>
        <Container>
          <SearchContainer>
            <SearchInput
              changeCallback={setSearchQuery}
              placeholder={"Search by name, cuisine or suburb"}
              filterVal={filter}
            />
            <CityFilter
              isSearchable={false}
              styles={customStyles}
              value={currentCity}
              onChange={setCurrentCity}
              options={cityOptions}></CityFilter>
          </SearchContainer>
          <Filters>
            <Filter bgImg={CocktailImg} onClick={handleFilter("cocktails")}>
              Cocktails
            </Filter>
            <Filter bgImg={RamenImg} onClick={handleFilter("ramen")}>
              Ramen
            </Filter>
            <Filter bgImg={BakeryImg} onClick={handleFilter("bakery")}>
              Bakery
            </Filter>
            <Filter bgImg={VeganImg} onClick={handleFilter("vegan")}>
              Vegan
            </Filter>
            <Filter bgImg={BurgerImg} onClick={handleFilter("burger")}>
              Burgers
            </Filter>
            <Filter bgImg={CafeImg} onClick={handleFilter("cafe")}>
              Cafe
            </Filter>
            <Filter bgImg={KoreanImg} onClick={handleFilter("korean")}>
              Korean
            </Filter>
            <Filter bgImg={JapaneseImg} onClick={handleFilter("japanese")}>
              Japanese
            </Filter>
            <Filter bgImg={AusImg} onClick={handleFilter("australian")}>
              Australian
            </Filter>
            <Filter bgImg={ItalianImg} onClick={handleFilter("italian")}>
              Italian
            </Filter>
            <Filter bgImg={FrenchImg} onClick={handleFilter("french")}>
              French
            </Filter>
          </Filters>
          {searchQuery && (
            <EstablishmentList
              estList={resultsList}
              totalAvail={searchTotal}></EstablishmentList>
          )}
          {!searchQuery && (
            <EstablishmentList
              estList={runningList}
              totalAvail={runningTotal}></EstablishmentList>
          )}
          {isLoading && (
            <LoaderWrapper>
              <LoadingSpinner />
            </LoaderWrapper>
          )}
          {!isLoading && !resultsList.length > 0 && (
            <LoadMoreWrapper>
              <LoadMore onClick={fetchPageOfEst}>Load more results</LoadMore>
            </LoadMoreWrapper>
          )}
          {!isLoading &&
            resultsList.length > 0 &&
            resultsList.length < searchTotal && (
              <LoadMoreWrapper>
                <LoadMore onClick={fetchPageOfSearch}>
                  Load more results
                </LoadMore>
              </LoadMoreWrapper>
            )}
        </Container>
      </Body>
    </Layout>
  );
};

const Opening = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Heading = styled(Title)`
  margin: auto;
  text-align: center;
  max-width: 600px;
  font-size: 2.2rem;
  line-height: 3rem;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }
`;

const City = styled.span`
  display: inline-block;
  color: black;
  margin: 0;
  border-bottom: 4px solid black;
  text-transform: uppercase;
`;

const SearchContainer = styled.div`
  display: grid;
  grid-template-columns: 60% auto;
  grid-gap: 100px;
  margin-bottom: 20px;

  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
  }
`;

const CityFilter = styled(Select)`
  width: 100%;
  font-size: 1.6rem;
  border: none;
`;

const LoadMoreWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const LoadMore = styled.button`
  background-color: white;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid black;
  font-size: 1.6rem;
`;

const Filters = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
`;

const Filter = styled.button`
  border-radius: 10px;
  height: 100px;
  min-width: 100px;
  max-width: 100px;
  border: none;
  background-color: #bebebe;
  font-size: 1.8rem;
  font-weight: bold;
  background-size: cover;
  color: white;
  margin-right: 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
    ${(props) => (props.bgImg ? `url(${props.bgImg})` : "")};

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    height: 90px;
    min-width: 90px;
    width: 90px;
    font-size: 1.4rem;
  }
`;
