import React from "react";
import styled from "styled-components/macro";
import infoicon from "../img/info.svg";
import rightarrowicon from "../img/right.svg";
import { MARKS, BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import comicon from "../img/com.svg";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { lgOutBndLink } from "../common/firebaseAnalytics";

export const Establishment = ({
  address,
  businessName,
  contactNumber,
  deliveroo,
  delivery,
  doorDash,
  easi,
  facebook,
  googleMapsLink,
  instagram,
  menuLog,
  notes,
  pickup,
  type,
  uberEats,
  bopple,
  website,
  menu,
}) => {
  const contactList = () => {
    let contactItems = [];
    const contactNode = (contact, children) => (
      <div
        onClick={() =>
          lgOutBndLink("est_contact", {
            business: businessName,
            contact: contact,
          })
        }>
        {children}
      </div>
    );

    if (contactNumber)
      contactItems.push({
        url: `tel:${contactNumber}`,
        component: contactNode("phone", contactNumber),
      });

    if (website)
      contactItems.push({
        url: website,
        component: contactNode("website", <img src={comicon} />),
      });

    if (instagram)
      contactItems.push({
        url: instagram,
        component: contactNode("instagram", <FaInstagram />),
      });

    if (facebook)
      contactItems.push({
        url: facebook,
        component: contactNode("instagram", <FaFacebook />),
      });

    return contactItems;
  };

  const deliveryList = () => {
    let deliveryItems = [];
    const deliveryNode = (service) => (
      <div
        onClick={() =>
          lgOutBndLink("est_delivery", {
            business: businessName,
            contact: service,
          })
        }>
        {service}
      </div>
    );

    if (uberEats) {
      deliveryItems.push({
        url: uberEats,
        component: deliveryNode("UberEats"),
      });
    }

    if (deliveroo) {
      deliveryItems.push({
        url: deliveroo,
        component: deliveryNode("Deliveroo"),
      });
    }

    if (bopple) {
      deliveryItems.push({ url: bopple, component: deliveryNode("Bopple") });
    }

    if (menuLog) {
      deliveryItems.push({
        url: menuLog,
        component: deliveryNode("MenuLog"),
      });
    }

    if (doorDash) {
      deliveryItems.push({
        url: doorDash,
        component: deliveryNode("DoorDash"),
      });
    }

    return deliveryItems;
  };

  return (
    <Wrapper>
      <EstablishmentContainer>
        <BusinessName>{businessName}</BusinessName>
        <Type>{type}</Type>
        <Address
          href={googleMapsLink}
          onClick={() => lgOutBndLink("est_gmaps", { business: businessName })}
          target="_blank"
          nounderline="true">
          {address}&#8594;
        </Address>
        <Contact>{createCrumbs(contactList())}</Contact>
        <Offering>
          {pickup && (
            <Offer>
              <OfferWrapper>
                Pick Up <MdDone />
              </OfferWrapper>
            </Offer>
          )}
          {delivery && (
            <Offer>
              <OfferWrapper>
                Delivery <MdDone />
              </OfferWrapper>
            </Offer>
          )}
          {menu && (
            <Menu
              onClick={() =>
                lgOutBndLink("est_menu", { business: businessName })
              }
              href={menu}
              target="_blank">
              MENU &#8594;
            </Menu>
          )}
        </Offering>
        {uberEats || deliveroo || menuLog || bopple ? (
          <DeliveryOptions>
            <Text>Online orders</Text>
            <Crumbs>{createCrumbs(deliveryList())}</Crumbs>
          </DeliveryOptions>
        ) : (
          <DeliveryOptions>
            <Text>Online orders</Text>
            <Text style={{ marginBottom: "3px" }}>Not yet available.</Text>
          </DeliveryOptions>
        )}
        {notes && (
          <Notes>
            <InfoIcon src={infoicon} />
            {parseNotesRichText(notes)}
          </Notes>
        )}
      </EstablishmentContainer>
    </Wrapper>
  );
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <NotesText>{children}</NotesText>,
    [INLINES.HYPERLINK]: (node, children) => (
      <NotesLink href={node.data.uri} target="_blank">
        {children}
      </NotesLink>
    ),
  },
  renderText: (text) => text,
};

const parseNotesRichText = (richTextJson) => {
  const firstParagraphOnly = richTextJson.content[0];
  richTextJson.content = [firstParagraphOnly];
  return documentToReactComponents(richTextJson, options);
};

const createCrumbs = (crumbList) => {
  const crumbs = crumbList.map((crumb, index) => {
    let crumbComponent = (
      <Crumb key={index} bold={"true"} href={crumb.url} target="_blank">
        {crumb.component}
      </Crumb>
    );

    if (index === crumbList.length - 1) {
      return crumbComponent;
    } else {
      return (
        <>
          {crumbComponent}
          <Slash />
        </>
      );
    }
  });
  return crumbs;
};

const Slash = () => {
  return <Divider>/</Divider>;
};

const Wrapper = styled.div`
  font-size: 1rem;
  background-color: #fafafa;
  padding: 7px 18px;
  border-radius: 10px;
  margin: auto;
  width: 100%;
  height: 100%;
`;

const Divider = styled.span`
  margin-left: 10px;
  margin-right: 10px;
  line-height: 2.5rem;
  display: inline-block;
`;

const StyledLink = styled(OutboundLink)`
  ${(props) => (props.bold ? "font-weight: bold" : "")};
  ${(props) => (props.nounderline ? "text-decoration: none" : "")};

  &:link {
    color: black;
  }

  &:visited {
    color: black;
  }
`;

const EstablishmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Offering = styled.div`
  margin-bottom: 20px;
`;

const Offer = styled.div`
  display: inline-block;
  font-size: 1.4rem;
  margin-right: 5px;
`;

const OfferWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 25px;
`;

const DeliveryOptions = styled.div`
  flex-basis: 20%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    flex-basis: 100%;
    margin-bottom: 1.5rem;
  }
`;

const BusinessName = styled.h3`
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0;
`;

const Type = styled.div`
  font-size: 17px;
  margin-bottom: 10px;
  padding: 0;
  font-style: italic;
`;

const Address = styled(StyledLink)`
  display: block;
  font-size: 17px;
  font-weight: normal;
  text-decoration: none;
  margin-bottom: 15px;
  line-height: 20px;
  height: 40px;
`;

const Contact = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Crumbs = styled.div``;

const Crumb = styled(StyledLink)`
  display: inline-block;
  height: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2rem;

  &:visited {
    color: black;
  }

  & img {
    height: 2rem;
    width: auto;
    /* width: 2rem; */
  }

  & svg {
    height: 2rem;
    width: auto;
    /* width: 2rem; */
  }
`;

const Menu = styled(OutboundLink)`
  font-weight: bold;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid black;
  font-size: 1.4rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  height: 25px;

  &:link {
    color: black;
    text-decoration: none;
  }

  &:visited {
    color: black;
  }
`;

const Text = styled.div`
  font-size: 1.4rem;
  margin-bottom: 5px;
`;

const InfoIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 10px;
`;

const Notes = styled.div`
  flex-basis: 100%;
  background-color: white;
  padding: 10px;
  display: flex;
  margin-top: 10px;
  border-radius: 5px;
`;

const NotesText = styled.div`
  font-size: 1.5rem;
  margin: 0;
`;

const NotesLink = styled.a`
  font-size: 1.5rem;

  &:link {
    color: black;
  }

  &:visited {
    color: black;
  }
`;
