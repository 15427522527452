import React from "react";
import styled from "styled-components/macro";
import { Establishment } from "./Establishment";
import {
  ListHeader,
  ListHeading,
  ListCounter
} from "../common/styled-components";

const EstablishmentList = ({ estList, totalAvail }) => {
  const renderEstablishments = list => {
    return list.map((est, index) => (
      <Establishment
        key={index}
        businessName={est.businessName}
        type={est.type}
        contactNumber={est.contactNumber}
        address={est.address}
        googleMapsLink={est.googleMapsLink}
        pickup={est.doesPickup}
        delivery={est.doesDelivery}
        notes={est.notes}
        website={est.websiteUrl}
        instagram={est.instagramUrl}
        facebook={est.facebookUrl}
        uberEats={est.uberEatsUrl}
        deliveroo={est.deliverooUrl}
        menuLog={est.menuLogUrl}
        heyYou={est.heyYouUrl}
        doorDash={est.doorDashUrl}
        bopple={est.boppleUrl}
        menu={est.menuUrl}
      />
    ));
  };

  return (
    <>
      <ListHeader>
        <ListHeading>BUSINESSES</ListHeading>
        <ListCounter>
          <strong>{totalAvail}</strong> AVAILABLE
        </ListCounter>
      </ListHeader>
      <Establishments>{renderEstablishments(estList)}</Establishments>
    </>
  );
};

export default EstablishmentList;

const Establishments = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-top: 13px;
`;

const EmptyListMessage = styled.div`
  text-align: center;
  font-size: 2rem;
  padding-top: 50px;
  padding-bottom: 50px;
`;
